<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-app id="inspire">
      <v-layout wrap justify-center class="login_page">
        <v-flex xs12 sm6 md6 lg4 px-2 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs12 lg11 xl8>
              <!-- #181A24 -->
              <v-card
                flat
                rounded="lg"
                color="#FFFFFF"
                style="border: 1px solid #4e5360"
              >
                <v-layout wrap justify-center>
                  <v-flex px-8 py-6>
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-center>
                        <Logo color="#FFFFFF" />
                      </v-flex>
                      <v-flex xs12 sm10 py-4 text-center>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #000000;
                            font-size: 20px;
                          "
                        >
                          Welcome Back
                        </span>
                        <br />
                        <span
                          style="
                            font-family: poppinsregular;
                            letter-spacing: 0.5px;
                            font-size: 15px;
                            color: #000000;
                          "
                        >
                          Please Enter your account details to get started.
                        </span>
                      </v-flex>
                      <v-form @submit.prevent="verifyInput">
                        <v-flex pt-5 xs12 text-left>
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <span class="textField"> Phone number</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-text-field
                                v-model="phone"
                                outlined
                                dense
                                hide-details
                                color="#979797DE"
                                placeholder="Enter Phone"
                              >
                              </v-text-field>
                            </v-flex>
                            <v-flex xs12 pt-3>
                              <span class="textField"> Password </span>
                            </v-flex>
                            <v-flex xs12>
                              <v-text-field
                                v-model="password"
                                outlined
                                dense
                                hide-details
                                placeholder="Enter Password"
                                color="#979797DE"
                                :append-icon="
                                  showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                              >
                              </v-text-field>
                              <v-flex xs12 style="text-align: end">
                                <router-link to="/ForgotPassword">
                                  <span
                                    style="
                                      text-transform: uppercase;
                                      font-family: poppinsregular;
                                      color: #000000d9;
                                      font-size: 14px;
                                    "
                                  >
                                    forgot password
                                  </span>
                                </router-link>
                              </v-flex>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pb-6 pt-10>
                          <v-btn
                            block
                            color="#30B868"
                            dark
                            :ripple="false"
                            depressed
                            type="submit"
                            @click="verifyInput"
                          >
                            <span style="font-family: opensansregular">
                              Login
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-form>
                      <v-flex xs12 pb-5>
                        <router-link to="/signUpForm">
                        <span
                          style="color: #000000; font-family: opensansregular; font-size: 15px;"
                          >Create an account ?</span
                        ><span
                          style="color: #30b868; font-family: poppinsmedium;font-size: 15px;"
                          >&nbsp;SIGN UP</span
                        >
                    </router-link>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      phone: null,
      password: null,
      showPassword: false,
    };
  },
  computed: {
    appLogin() {
      if (this.$store.state.isLoggedIn) return this.$store.state.isLoggedIn;
      else return null;
    },
  },
  methods: {
    verifyInput() {
      if (!this.phone) {
        this.msg = "Please Provide Phone Number";
        this.showSnackBar = true;
        return;
      }
      if (!this.password) {
        this.msg = "Please Provide Password";
        this.showSnackBar = true;
        return;
      }
      this.login();
    },
    login() {
      this.appLoading = true;
      let user = {};
      user["phone"] = this.phone;
      user["password"] = this.password;
      user["guestId"] = localStorage.getItem("userId");
      // user["accessType"] = "signin";
      axios({
        method: "POST",
        url: "/customer/login",
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // response.data["lastPage"] = this.$route.query.lastPage;
            this.$store.commit("userType", response.data.role);
            this.$store.commit("userData", response.data.userData);
            this.$store.commit("changeCart", response.data.totalLength);
            localStorage.setItem("token", response.data.token);
            localStorage.removeItem("userId");
            this.$store.commit("loginUser", response.data);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
  },
};
</script>